import React from "react"
import Layout from "../components/layout"
import PostMain from "../components/post-main"
import { graphql } from 'gatsby';

const WpPost = ({data}) => {
  const {wpPost={}} = data || {};
  return (
    <Layout>
      <PostMain {...wpPost} />
    </Layout>
  )}

export default WpPost

export const query = graphql`
    query($id: String!) {
        wpPost(id: { eq: $id }) {
            title
            content
            excerpt
            date(formatString: "MMMM DD, YYYY")
        }
    }
`
